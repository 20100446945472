/* eslint-disable no-use-before-define */
import VueInstance from 'vue';
import { abilitiesPlugin } from '@casl/vue';
import store from '@/store/index';
import registerStore from '@/views/Register/store';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import {
	LOGIN, LOGOUT, CREATE_CART,
} from '@/config/endpoint';
import { api } from '@/config/axios/index';
// import router from '@/router';
import DefinePermissions from '@/util/DefinePermissions';

const cookie = require('vue-cookie');

const refreshCartCookie = (cartId) => {
	cookie.delete('cart_id');
	// TODO: Make configurable the placement of cart_id and cart_id name
	if (typeof cartId !== 'undefined') {
		cookie.set('cart_id', cartId, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` });
		return null;
	}
	const { method, endpoint } = CREATE_CART;
	api[method](endpoint).then((response) => {
		cookie.set('cart_id', response.data.response.cart_id, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` });
	});
	return null;
};

const handleLogin = (token, loginResponse) => store.dispatch('login/login', token).then(() => user.init().then(() => {
	refreshCartCookie();
	store.dispatch('replicated/siteOff');
	store.dispatch('replicated/siteReplicatedSponsorOff');
	store.dispatch('replicated/siteReplicatedPageOff');
	registerStore.dispatch('removePosition');
	return loginResponse;
}).catch((error) => Promise.reject(error))).catch((error) => Promise.reject(error));

const user = {
	init: () => store.dispatch('login/init').then((response) => {
		const { permissions } = response.data.response;
		if (typeof permissions === 'object') {
			VueInstance.use(abilitiesPlugin, DefinePermissions(permissions));
		}
		return store.dispatch('login/startSession');
	}).catch((error) => Promise.reject(error)),
	login(username, password, tfaCode, captcha) {
		let payload = { username, password, 'g-recaptcha-response': captcha };
		if (tfaCode) {
			payload = { ...payload, code: tfaCode };
		}
		// login is not an arrow function because its <<this>> context is needed
		return api[LOGIN.method](LOGIN.endpoint, payload).then((loginResponse) => {
			if (typeof loginResponse.data.response.tfa_enabled !== 'undefined') {
				return Promise.resolve(loginResponse.data.response);
			}
			const token = loginResponse.data.response.access_token;
			return handleLogin(token, loginResponse);
		}).catch((error) => Promise.reject(error));
	},
	logout: () => api[LOGOUT.method](LOGOUT.endpoint).then((response) => {
		refreshCartCookie();
		store.dispatch('login/logout').then(() => response).catch((error) => Promise.reject(error));
		registerStore.dispatch('removePosition');
	}),
	ready: () => store.getters['login/ready'],
	auth: () => store.getters['login/authenticated'],
	details: () => store.getters['login/user'],
	setUser(info) {
		if (typeof info === 'object') {
			store.dispatch('login/setUser', { ...this.details(), ...info });
		}
	},
	refreshCartId: refreshCartCookie,
	getCartId: () => cookie.get('cart_id'),
	handleLogin,
};

const Login = {
	install(Vue) {
		user.init().catch(() => {});
		Vue.prototype.$user = user;
	},
};

export default Login;
