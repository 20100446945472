export default {};

const CheckoutOldPersonalInformation = {
	validation: 'personal_information',
};
const CheckoutOldShipping = {
	validation: 'shipping',
};
const CheckoutOldPaymentMethod = {
	validation: 'payment',
};
const CheckoutOldConfirmation = {
	validation: 'all',
};
const RegisterOldProduct = {
	validation: 'product',
};
const RegisterOldPersonalInformation = {
	validation: 'personal_information',
};
const RegisterOldShipping = {
	validation: 'shipping',
};
const RegisterOldPaymentMethod = {
	validation: 'payment',
};
const RegisterOldConfirmation = {
	validation: 'all',
};
const RegisterPack = {
	validation: 'product',
};
const RegisterPersonalInformation = {
	validation: 'personal_information',
};
const RegisterShipping = {
	validation: 'shipping',
};
const RegisterPayment = {
	validation: 'payment',
};
const RegisterConfirmation = {
	innerSteps: [
		{ RegisterPersonalInformation },
		{ RegisterShipping },
		{ RegisterPayment },
	],
	validation: 'all',
};
const BecomeDistributorPack = {
	validation: 'product',
};
const BecomeDistributorAccountAndShipping = {
	validation: 'shipping',
};
const BecomeDistributorPaymentMethod = {
	validation: 'payment',
};
const BecomeDistributorConfirmation = {
	validation: 'all',
};
const RenewalPaymentMethod = {
	validation: 'payment',
};
const RenewalConfirmation = {
	validation: 'all',
};
const TransferFeeShipping = {
	validation: 'shipping',
};
const TransferFeePayment = {
	validation: 'payment',
};
const TransferFeeProduct = {
	validation: 'product',
};
const TransferFeeConfirmation = {
	innerSteps: [
		{ TransferFeeShipping },
		{ TransferFeePayment },
	],
	validation: 'all',
};
const PreregisterPersonalInformation = {
	validation: 'personal_information',
};
// const PreregisterShipping = {
// 	validation: 'shipping',
// };
// const PreregisterPaymentMethod = {
// 	validation: 'payment',
// };
const PreregisterConfirmation = {
	validation: 'all',
};
const SoftRegisterProduct = {
	validation: 'product',
};
const SoftRegisterPersonalInformation = {
	validation: 'personal_information',
};
const SoftRegisterShipping = {
	validation: 'shipping',
};
const SoftRegisterPaymentMethod = {
	validation: 'payment',
};
const SoftRegisterConfirmation = {
	validation: 'all',
};
const PurchaseShipping = {
	validation: 'shipping',
};
const PurchasePayment = {
	validation: 'payment',
};
const PurchaseConfirmation = {
	innerSteps: [
		{ PurchaseShipping },
		{ PurchasePayment },
	],
	validation: 'all',
};
const PSPurchaseConfirmation = {
	innerSteps: [
		{ PurchaseShipping },
		{ PurchasePayment },
	],
	validation: 'all',
};
const CheckoutPersonalInformation = {
	validation: 'personal_information',
};
const CheckoutShipping = {
	validation: 'shipping',
};
const CheckoutPayment = {
	validation: 'payment',
};
const CheckoutConfirmation = {
	innerSteps: [
		{ CheckoutPersonalInformation },
		{ CheckoutShipping },
		{ CheckoutPayment },
	],
	validation: 'all',
};

const BecomeAffiliateConfirmation = {
	innerSteps: [
		{ CheckoutPersonalInformation },
	],
	validation: 'all',
};

export const REGISTER_OLD_STEPS = {
	RegisterOldProduct,
	RegisterOldPersonalInformation,
	RegisterOldShipping,
	RegisterOldPaymentMethod,
	RegisterOldConfirmation,
};

export const REGISTER_STEPS = {
	RegisterPack,
	RegisterConfirmation,
};

export const BECOME_DISTRIBUTOR_STEPS = {
	BecomeDistributorPack,
	BecomeDistributorAccountAndShipping,
	BecomeDistributorPaymentMethod,
	BecomeDistributorConfirmation,
};

export const CHECKOUT_OLD_LOGGED_STEPS = {
	CheckoutOldShipping,
	CheckoutOldPaymentMethod,
	CheckoutOldConfirmation,
};

export const CHECKOUT_OLD_GUEST_STEPS = {
	CheckoutOldPersonalInformation,
	CheckoutOldShipping,
	CheckoutOldPaymentMethod,
	CheckoutOldConfirmation,
};

export const PREREGISTER_STEPS = {
	PreregisterPersonalInformation,
	// PreregisterShipping,
	// PreregisterPaymentMethod,
	PreregisterConfirmation,
};

export const SOFTREGISTER_STEPS = {
	SoftRegisterProduct,
	SoftRegisterPersonalInformation,
	SoftRegisterShipping,
	SoftRegisterPaymentMethod,
	SoftRegisterConfirmation,
};

export const RENEWAL_STEPS = {
	RenewalPaymentMethod,
	RenewalConfirmation,
};

export const TRANSFER_FEE_STEPS = {
	TransferFeeProduct,
	TransferFeeConfirmation,
};

export const PURCHASE_LOGGED_STEPS = {
	PurchaseConfirmation,
};

export const PS_PURCHASE_LOGGED_STEPS = {
	PSPurchaseConfirmation,
};

export const CHECKOUT_GUEST_STEPS = {
	CheckoutConfirmation,
};

export const BECOME_AFFILIATE_STEPS = {
	BecomeAffiliateConfirmation,
};

export const AUTOSHIP_MIN_QTY = {
	US: 1,
	MX: 1,
	JP: 1,
	CA: 1,
	HU: 1,
	AT: 1,
	BE: 1,
	BG: 1,
	HR: 1,
	CY: 1,
	CZ: 1,
	DK: 1,
	EE: 1,
	FI: 1,
	FR: 1,
	DE: 1,
	GR: 1,
	IE: 1,
	IT: 1,
	LV: 1,
	LT: 1,
	LU: 1,
	MT: 1,
	NL: 1,
	PL: 1,
	PT: 1,
	RO: 1,
	GB: 1,
	SK: 1,
	SI: 1,
	ES: 1,
	SE: 1,
	PH: 1,
	AR: 1,
	AU: 1,
	BD: 1,
	BR: 1,
	CL: 1,
	CO: 1,
	CR: 1,
	CD: 1,
	EC: 1,
	GH: 1,
	GI: 1,
	HN: 1,
	HK: 1,
	IS: 1,
	IN: 1,
	ID: 1,
	IL: 1,
	KE: 1,
	LI: 1,
	MQ: 1,
	MU: 1,
	MC: 1,
	MA: 1,
	NZ: 1,
	NE: 1,
	NO: 1,
	PA: 1,
	PE: 1,
	RS: 1,
	SG: 1,
	ZA: 1,
	CH: 1,
	TW: 1,
	TR: 1,
	UG: 1,
	UY: 1,
	ZM: 1,
	NG: 1,
	MY: 1,
	DO: 1,
	PY: 1,
	PG: 1,
	GT: 1,
	SV: 1,
	CN: 1,
};

export const STORAGE_STEPS = true;
