/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import LANGS from '@/settings/Languages';

const importLanguages = (paths) => {
	const response = {};
	LANGS.forEach((lang) => {
		if (typeof paths === 'string') {
			try {
				response[lang] = require(`${paths}/${lang}`);
			} catch (error) {
				console.warn(`Something went wrong with the lang ${lang} in the path ${paths}`);
			}
		} else if (paths instanceof Array) {
			paths.forEach((item) => {
				try {
					const content = require(`${item}/${lang}`);
					response[lang] = { ...response[lang], ...content };
				} catch (error) {
					console.warn(`Something went wrong with the lang ${lang} in the path ${item}`);
				}
			});
		}
	});
	return response;
};

export const Addresses = importLanguages('./Addresses');
export const Alerts = importLanguages('./Alerts');
export const Months = importLanguages('./Months');
export const Comparison = importLanguages('./Report/Sales/Comparison');
export const AutoshipInformation = importLanguages('./AutoshipInformation');
export const AutoshipPeriodicity = importLanguages('./AutoshipPeriodicities');
export const Dates = importLanguages('./Dates');
export const Report = importLanguages(['./Months', './Grids', './Report', './Report/Sales', './Report/SalesDetails', './Report/Sales/Products', './Report/Taxes', './Report/Commissions', './Report/UserNotes']);
export const CashPayments = importLanguages('./CashPayments');
export const OrderVerifications = importLanguages('./OrderVerifications');
export const Auth = importLanguages('./Auth');
export const Cart = importLanguages('./Cart');
export const CustomersInformation = importLanguages('./CustomersInformation');
export const DistributorsInformation = importLanguages('./DistributorsInformation');
export const PersonalInformation = importLanguages('./Profile/PersonalInformation');
export const AccountInformation = importLanguages('./Profile/AccountInformation');
export const Common = importLanguages(['./Common', './Common/PageTitles', './Common/HeaderTitles', './Common/Menu', './Validations', './Common/Currencies']);
export const Countries = importLanguages('./Countries');
export const Grids = importLanguages('./Grids');
export const Header = importLanguages('./Header');
export const Support = importLanguages('./Support');
export const Faq = importLanguages('./Faq');
export const AccountCanceled = importLanguages('./AccountCanceled');
export const Profile = importLanguages([
	'./Profile',
	'./Profile/AccountInformation',
	'./Profile/PersonalInformation',
	'./Profile/ReplicatedSite',
	'./Profile/CreditCard',
	'./Profile/AddressBook',
]);
export const Users = importLanguages('./Users');
export const Commissions = importLanguages('./Report/Commissions');
export const Products = importLanguages([
	'./Products',
	'./Products/Info',
	'./Products/Properties',
]);
export const Validations = importLanguages('./Validations');
export const Permissions = importLanguages('./Permissions');
export const Time = importLanguages('./Time');
export const Tooltip = importLanguages('./Tooltip');
export const TopUsers = importLanguages('./TopUsers');
export const Network = importLanguages('./Network');
export const UserFiles = importLanguages('./UserFiles');
export const WeeklyPayout = importLanguages('./WeeklyPayout');
export const Ranks = importLanguages('./Ranks');
export const Promotions = importLanguages('./Promotions');
export const Packages = importLanguages('./Packages');
export const MembershipRenewal = importLanguages('./MembershipRenewal');
export const AccountTransferFee = importLanguages('./AccountTransferFee');
export const ForceUsernameChange = importLanguages('./ForceUsernameChange');
export const PaymentMethods = importLanguages('./PaymentMethods');
export const PayoutMethods = importLanguages('./PayoutMethods');
export const SalesPaymentMethods = importLanguages('./SalesPaymentMethods');
export const Store = importLanguages(['./Store', './Store/Header', './Store/Footer', './Store/Menu']);
export const StoreMenu = importLanguages('./Store/Menu');
export const WeeklyCommissions = importLanguages('./WeeklyCommissions');
export const OffersModal = importLanguages('./OffersModal');
export const PhoneVerification = importLanguages('./PhoneVerification');
export const Purchase = importLanguages([
	'./Purchase',
	'./Purchase/Packages',
	'./Purchase/GeneralInformation',
	'./Purchase/RegisterPacks',
	'./Purchase/ShippingAddress',
	'./Purchase/PaymentMethod',
	'./Purchase/Success',
]);
export const CustomerPromoModal = importLanguages('./CustomerPromoModal');
export const PurchasePayment = importLanguages('./Purchase/PaymentMethod');
export const PurchaseShipping = importLanguages('./Purchase/ShippingAddress');
export const Dashboard = importLanguages('./Dashboard');
export const Popups = importLanguages('./Popups');
export const NIN = importLanguages('./NIN');
export const CommandPanel = importLanguages('./CommandPanel');
export const Announcements = importLanguages('./Announcements');
export const BinaryVolume = importLanguages('./Report/BinaryVolume');
export const Sms = importLanguages('./Sms');
export const PushNotifications = importLanguages('./PushNotifications');
export const Geofences = importLanguages('./Geofences');
export const TrackingStatus = importLanguages('./TrackingStatus');
export const Order = importLanguages('./Order');
export const OrderTypes = importLanguages('./OrdersDetails/OrdersTypes');
export const OrdersDetails = importLanguages([
	'./OrdersDetails',
	'./OrdersDetails/OrdersTypes',
]);
export const Coupons = importLanguages('./Coupons');
export const Wallets = importLanguages('./Wallets');
export const WalletTypes = importLanguages('./Wallets/Types');
export const WalletTransactions = importLanguages('./Wallets/Transactions');
export const TransactionCategories = importLanguages('./TransactionCategories');
export const MediaLibrary = importLanguages(['./MediaLibrary', './MediaLibrary/Categories']);
export const AccessGroups = importLanguages('./AccessGroups');
export const Settings = importLanguages('./Settings');
export const Languages = importLanguages(['./Languages']);
export const Invitations = importLanguages(['./Invitations']);
export const Credits = importLanguages(['./Credits']);
export const PersonalBusinessVolume = importLanguages('./Report/PersonalBusinessVolume');
export const ReplicatedSiteAnalytics = importLanguages('./Report/ReplicatedSiteAnalytics');
export const LifetimeValue = importLanguages('./Report/LifetimeValue');
export const Banners = importLanguages('./Banners');
export const HighlightVideos = importLanguages('./HighlightVideos');
export const VideoCategories = importLanguages('./VideoCategories');
export const Events = importLanguages('./Events');
export const EventTickets = importLanguages(['./EventTickets']);
export const UnilevelRanks = importLanguages('./Report/UnilevelRanks');
export const CryptoPayments = importLanguages('./CryptoPayments');
export const Notifications = importLanguages([
	'./PushNotifications',
	'./Sms',
	'./Notifications',
]);
export const OrganizationNotifications = importLanguages('./OrganizationNotifications');
export const ManualOrder = importLanguages('./ManualOrder');
export const Settlement = importLanguages('./Settlements');
export const Tags = importLanguages('./Tags');
export const MobileApp = importLanguages('./MobileApp');
export const LandingPages = importLanguages([
	'./LandingPages',
	'./LandingPages/Bran',
	'./LandingPages/Zlem',
	'./LandingPages/Uuth',
	'./LandingPages/Plos',
	'./LandingPages/Byom',
	'./LandingPages/Lite',
	'./LandingPages/Tuun',
	'./LandingPages/Opportunity1',
	'./LandingPages/Opportunity2',
	'./LandingPages/HealthyWeightLoss',
	'./LandingPages/Agency',
	'./LandingPages/Agency/Bran',
	'./LandingPages/Agency/Uuth',
	'./LandingPages/Agency/Zlem',
	'./LandingPages/Agency/Plos',
	'./LandingPages/Agency/Byom',
	'./LandingPages/Agency/Tuun',
]);
export const Testimonials = importLanguages('./Testimonials');
export const Inventory = importLanguages('./Inventory');
export const InventoryChanges = importLanguages('./Inventory/InventoryChanges');
export const SplitPayment = importLanguages('./SplitPayment');
export const FreeProductsModal = importLanguages('./FreeProductsModal');
export const ExtraProductsModal = importLanguages('./ExtraProductsModal');
export const AdditionalProductsModal = importLanguages('./AdditionalProductsModal');
export const FreeEvents = importLanguages('./FreeEvents');
export const BecomeAffiliate = importLanguages('./BecomeAffiliate');
export const CustomCarts = importLanguages('./CustomCarts');
export const MultiPayment = importLanguages('./MultiPayment');
export const GiftOrders = importLanguages('./GiftOrders');
export const AgencyPromos = importLanguages('./AgencyPromos');

export const ProductReviews = importLanguages('./ProductReviews');
export const AccountSteps = importLanguages('./AccountSteps');
export const PreCheckoutModal = importLanguages('./PreCheckoutModal');
export const SameAddressAsCardStatement = importLanguages('./SameAddressAsCardStatement');
export const MemberRewards = importLanguages('./MemberRewards');
export const Agency = importLanguages('./Agency');
export const AgencyTaglines = importLanguages('./Agency/Taglines');
export const CustomPricing = importLanguages('./CustomPricing');

export default Common;
