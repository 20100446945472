export const COUNTRY_VAR_NAME = 'country';
export const REGISTER_COUNTRY_VAR_NAME = 'registerCountry';
export const REGISTER_COUNTRY_ROUTE_NAMES = ['RegisterPack', 'RegisterConfirmation'];
export const SHOW_USER_COUNTRY = true;
export const DEFAULT_COUNTRY = 'US';
export const PICKUP_STORE = 'XP';

export const DEFAULT_COUNTRY_CURRENCY = 'USD';
export const COUNTRY_CURRENCIES = {
	US: 'USD',
};

export const VALID_COUNTRIES = [
	'CA', 'JP', 'US', 'MX', 'HU', 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT',
	'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'GB', 'SK', 'SI', 'ES', 'SE', 'PH', 'AR', 'AU', 'BD', 'BR', 'CL',
	'CO', 'CR', 'CD', 'EC', 'GH', 'GI', 'HN', 'HK', 'IS', 'IN', 'ID', 'IL', 'KE', 'LI', 'MQ', 'MU', 'MC', 'MA', 'NZ',
	'NO', 'PA', 'PE', 'RS', 'SG', 'ZA', 'CH', 'TW', 'TR', 'UG', 'UY', 'ZM', 'NG', 'MY', 'DO', 'PY', 'PG', 'GT', 'SV',
	'CN',
];
export const ENTITY_MESSAGES_COUNTRIES = [
	'HU', 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT',
	'NL', 'PL', 'PT', 'RO', 'GB', 'SK', 'SI', 'ES', 'SE', 'PG',
];
export const EU_TERMS_COUNTRIES = [];
export const NO_DAY_LIMIT_CASH_PAYMENT = ['CO'];
export const GOVERNMENT_DOC_COUNTRIES = ['PG', 'TR', 'GI', 'IS', 'LI', 'MC', 'NO', 'RS', 'GB'];
export const PRE_CONFIRMATION_NOTE_COUNTRIES = ['CR', 'HN'];
export const SHOW_TRIP_POINTS_COUNTRIES = ['CO'];

export const EARLY_LAUNCH_COUNTRIES = [];
export const ONLY_MEMBERS_COUNTRIES = [];

export default {};
