import { RENEWAL_STEPS } from '@/settings/Wizard';

const MainHeader = () => import('@/views/Layouts/Main/Header');
const MembershipRenewalContainer = () => import('@/views/Layouts/Main/MembershipRenewalContainer');
const Header = () => import('@/views/Layouts/Purchase');

export const RenewalSteps = {
	path: '/renewal',
	name: 'Renewal',
	redirect: { name: Object.keys(RENEWAL_STEPS)[0] },
	component: Header,
	children: [
		{
			path: '/renewal/payment-method',
			name: 'RenewalPaymentMethod',
			component: () => import('@/views/Renewal/PaymentMethod'),
			meta: {
				auth: true,
				role: ['distributor'],
			},
		},
		{
			path: '/renewal/confirmation',
			name: 'RenewalConfirmation',
			component: () => import('@/views/Renewal/Confirmation'),
			meta: {
				auth: true,
				role: ['distributor'],
			},
		},
		{
			path: '/renewal/success',
			name: 'RenewalSuccess',
			component: () => import('@/views/Renewal/Success'),
			meta: {
				auth: true,
				role: ['distributor'],
			},
			// props: (route) => ({
			// 	paymentMethodName: route.query.payment_method ?? '',
			// 	verificationRequired: !!route.query.verification_required ?? false,
			// }),
		},
	],
};

export const RenewalMain = {
	path: '/membership-renewal',
	redirect: '/membership-renewal',
	name: 'MembershipRenewalContainer',
	component: MembershipRenewalContainer,
	children: [
		{
			path: '/membership-renewal',
			name: 'MembershipRenewalMain',
			redirect: '/membership-renewal',
			component: MainHeader,
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/membership-renewal',
					name: 'MembershipRenewal',
					component: () => import('@/views/MembershipRenewal'),
					meta: {
						auth: true,
						role: ['distributor'],
					},
				},
			],
		},
	],
};

export default {};
