const Header = () => import('@/views/Layouts/Main/Header');

export default {
	path: '/inventory',
	name: 'Inventory',
	component: Header,
	redirect: '/inventory/summary',
	children: [
		{
			path: '/inventory/summary',
			name: 'SummaryInventory',
			component: () => import('@/views/Inventory'),
			redirect: '/inventory/summary/all',
			meta: {
				auth: true,
				section: 'inventory',
				permissions: 'view',
			},
			children: [
				{
					path: '/inventory/summary/all',
					name: 'AllInventory',
					component: () => import('@/views/Inventory'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/inventory/summary/main',
					name: 'MainInventory',
					component: () => import('@/views/Inventory'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/inventory/summary/vpack',
					name: 'VPackInventory',
					component: () => import('@/views/Inventory'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/inventory/summary/swag',
					name: 'SwagInventory',
					component: () => import('@/views/Inventory'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/inventory/summary/wearables',
					name: 'WearablesInventory',
					component: () => import('@/views/Inventory'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/inventory/summary/lites',
					name: 'LitesInventory',
					component: () => import('@/views/Inventory'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/inventory/summary/books',
					name: 'BooksInventory',
					component: () => import('@/views/Inventory'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/inventory/in-transit',
			name: 'InTransitInventory',
			component: () => import('@/views/Inventory/InTransitInventory.vue'),
			meta: {
				auth: true,
				section: 'inventory',
				permissions: 'view',
			},
		},
		{
			path: '/inventory/logs',
			name: 'InventoryLogs',
			component: () => import('@/views/Inventory/Logs'),
			redirect: '/inventory/logs/changes',
			meta: {
				auth: true,
			},
			children: [
				{
					path: '/inventory/logs/changes',
					name: 'InventoryChanges',
					component: () => import('@/views/Inventory/Logs/InventoryChanges'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
				{
					path: '/inventory/logs/manual-orders',
					name: 'ManualOrdersLogs',
					component: () => import('@/views/Orders'),
					meta: {
						auth: true,
						section: 'orders',
						permissions: 'view',
					},
				},
				{
					path: '/inventory/logs/manual-orders-types',
					name: 'ManualOrderTypes',
					component: () => import('@/views/Inventory/Logs/ManualOrderTypes.vue'),
					meta: {
						auth: true,
						section: 'inventory',
						permissions: 'view',
					},
				},
			],
		},
	],
};
