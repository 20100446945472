<template>
	<div>
		<div class="form-group mb-0">
			<slot
				v-if="labelText.length"
				name="label">
				<label :for="nameValue">{{ labelText }}
					<span
						v-if="required"
						class="text-danger">*</span>
				</label>
			</slot>
			<input
				v-model="query"
				:placeholder="placeholder ? translate(placeholder) : admin.includes($user.details().type) ? translate('autocomplete_placeholder') : translate('autocomplete_placeholder_non_staff')"
				type="text"
				class="form-control mb-1"
				autocomplete="off"
				:name="nameValue"
				:class="customError || isEmpty ? 'is-invalid' : ''"
				@input="defineResult"
				@keypress.esc="showList = false"
				@blur="hideList()"
				@focus="showList = true">
			<template v-if="hasError">
				<slot name="empty_result_message">
					<span
						v-if="isEmpty"
						class="animated invalid-feedback fadeIn text-danger mt-0"> {{ emptyResultMessage }} </span>
					<span
						v-if="isEmpty && customError"
						class="animated invalid-feedback fadeIn text-danger mt-0"> - </span>
					<span
						v-if="customError"
						:class="nameValue !== 'search' ? 'position-absolute' : ''"
						class="animated invalid-feedback fadeIn text-danger mt-0 "> {{ customError }} </span>
				</slot>
			</template>
		</div>
		<slot name="result_list">
			<transition name="slide-fade">
				<div
					v-show="showList && items.length"
					class="form-group relative">
					<ul
						v-if="items.length"
						class="list-group search-results-list w-100 mt-0">
						<li
							v-for="(item, index) in items"
							:key="index"
							class="list-group-item list-group-item-action"
							@click="defineResult(item)">
							{{ item[q] }}
						</li>
					</ul>
				</div>
			</transition>
		</slot>
	</div>
</template>
<script>
import { api } from '@/config/axios';
import { admin } from '@/settings/Roles';

const { CancelToken } = require('axios');

export default {
	name: 'Search',
	props: {
		endpoint: {
			type: String,
			required: true,
		},
		returnKey: {
			type: String,
			default: '',
		},
		labelText: {
			type: String,
			default: '',
		},
		defaultParams: {
			type: Object,
			default() { return {}; },
		},
		emptyResultMessage: {
			type: String,
			default: 'No Results Found',
		},
		placeholder: {
			type: String,
			default: '',
		},
		q: {
			type: String,
			default: 'q',
		},
		initValue: {
			type: String,
			default: '',
		},
		customError: {
			type: [Boolean, String], // eslint-disable-line no-bitwise
			validator: (value) => typeof value === 'string' || typeof value === 'boolean',
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		nameValue: {
			type: String,
			default: 'search',
		},
		value: {
			type: [String, Number],
			default: '',
		},
	},
	data() {
		return {
			query: '',
			showList: true,
			items: [],
			isEmpty: false,
			cancel: () => null,
			admin,
		};
	},
	computed: {
		getEndpoint() {
			let response = Object.keys(this.defaultParams).map((item) => `${item}=${this.defaultParams[item]}`).join('&');
			response = `${this.endpoint}?${this.q}=${this.query}&${response}`;
			return response;
		},
		hasError() {
			return this.isEmpty || this.customError;
		},
	},
	watch: {
		initValue(value) {
			this.query = value;
		},
		value(value) {
			this.query = value;
			this.items = [];
		},
	},
	created() {
		this.setValueToQuery();
	},
	methods: {
		setValueToQuery() {
			const { query } = this.$route;
			let response = '';
			if (typeof this.initValue === 'string') {
				response = this.initValue;
			}
			if (typeof query[this.q] !== 'undefined') {
				response = query[this.q];
			}
			if (typeof query[this.returnKey] !== 'undefined') {
				response = query[this.returnKey];
			}
			this.query = response;
		},
		defineResult(result) {
			let response = null;
			const { target } = result;
			if (typeof target !== 'undefined' && typeof target.value !== 'undefined') {
				this.isEmpty = false;
				this.search();
				response = target.value;
			} else if (typeof result === 'object') {
				response = result[this.returnKey];

				if (typeof response === 'undefined') {
					response = result[this.q];
				}

				if (typeof response !== 'string') {
					response = null;
				}
			} else if (typeof result === 'string') {
				response = result;
			}
			if (typeof response === 'string') {
				this.showList = false;
				this.query = response;
				this.$emit('input', response);
			}
			this.$emit('result', result);
			return null;
		},
		hideList() {
			setTimeout(() => {
				this.showList = false;
				this.$emit('blur', this.query);
			}, 500);
		},
		search() {
			if (this.timeToSearch) {
				clearTimeout(this.timeToSearch);
			}
			this.timeToSearch = setTimeout(() => {
				if (this.query.length < 1) {
					return null;
				}
				this.items = [];
				this.cancel();
				return api.get(this.getEndpoint, {
					cancelToken: new CancelToken((c) => { this.cancel = c; }),
				}).then((response) => {
					const { data } = response.data;
					this.items = data.map((item) => {
						const result = { ...item.attributes };
						result.id = item.id;
						result[this.q] = item.attributes[this.q];
						if (typeof item.attributes[this.returnKey] !== 'undefined') {
							result[this.returnKey] = item.attributes[this.returnKey];
						}
						return result;
					});
					this.showList = true;
					if (this.items.length === 0) {
						this.isEmpty = true;
					}
				}).catch((error) => console.warn(error));
			}, 500);
		},
	},
};
</script>
<style>
	.slide-fade-enter-active {
		transition: all .10s ease;
	}
	.slide-fade-leave-active {
		transition: all .10s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateX(10px);
		opacity: 0;
	}
	.list-group.search-results-list {
		/* padding-left: 15px; */
		/* padding-right: 15px; */
		position: absolute;
		left: 0;
		z-index: 5;
		cursor: pointer;
		overflow-y: scroll;
		height: 400px;
	}
	.relative{
		position:relative;
	}

</style>
